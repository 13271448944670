import request from '@/utils/request'

const api = {
  module: '/assess/module/module'
}

export function getModule (parameter) {
  return request({
    url: api.module,
    method: 'get',
    params: parameter
  })
}

// export function add (parameter) {
//   return request({
//     url: api.add,
//     method: 'post',
//     data: parameter
//   })
// }
