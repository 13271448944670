<template>
  <div class="container">
    <!-- <div>
        <van-icon name="arrow-left" />
      </div> -->
    <div class="assess">
      <div class="nav">
        <div @click="backIndex">
          <van-icon name="arrow-left" />
        </div>
      </div>
      <div class="head">
        <div class="assess-base">
          <div class="community">{{ assess.communityName }}</div>
          <div class="property"><van-icon name="manager-o" />{{ assess.property }}</div>
          <div class="property"><van-icon name="location-o" />{{ `${assess.district}-${assess.block}-${assess.com}` }}</div>
        </div>

        <div class="assess-info">
          <div>
            <div class="total-num">{{ complete+undone }}</div>
            <div>全部</div>
          </div>
          <div @click="onComplete">
            <div class="total-num">{{ complete }}</div>
            <div>已完成</div>
          </div>
          <div @click="onUndone">
            <div class="total-num">{{ undone }}</div>
            <div>未完成</div>
          </div>
      </div>

      </div>
    </div>

    <div class="module-wrap">
      <van-collapse v-model="activeNames">
        <van-collapse-item v-for="item in moduleList" :key="item.id" :title="item.name" :name="item.id">

          <div class="topic" v-for="topic in item.topic" :key="topic.id" @click="onSelectTopic(topic.id)">
            <div>
              <div class="topic-status">
                <span>{{ `第${topic.serial}题` }}</span>
                <span>
                  <template v-if="topic.status === 1">已完成</template>
                  <template v-else>未完成</template>
                </span>
              </div>
              <div class="topic-content">
                <span>{{ topic.content }}</span>
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <!-- <van-popup v-model:show="popupShow" position="left" :style="{ width: '30%' }" /> -->
    <van-popup v-model:show="popupShow" position="left" :style="{ width: '30%', height: '100%' }" >
      <div class="status-main">
        <div class="status-item" v-for="item in statusList" :key="item.id" @click="onSelectTopic(item.id)">
          <span>第{{item.serial}}题</span>
          <span>{{ item.status === 0 ? '未完成': '已完成' }}</span>

        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getModule } from '@/api/module.js'

import { Icon, Collapse, CollapseItem, Popup } from 'vant'
export default {
  name: 'Module',
  components: {
    [Icon.name]: Icon,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Popup.name]: Popup
  },
  data () {
    return {
      assess: {},
      moduleList: [],
      activeNames: [],
      complete: 0,
      undone: 0,
      popupShow: false,
      statusList: []
    }
  },
  created () {
    getModule({ assessId: this.$route.params.assessId }).then(res => {
      console.log('getModule', res)
      this.assess = res.data.assess
      this.moduleList = res.data.module
      this.moduleList.forEach(moduleItem => {
        moduleItem.topic.forEach(topic => {
          if (topic.status === 1) {
            // 已完成
            this.complete += 1
          } else {
            this.undone += 1
          }
        })
      })
    })
  },
  methods: {
    onSelectTopic (id) {
      this.$router.push({ name: 'Topic', params: { assessId: this.$route.params.assessId, topicId: id, communityName: this.assess.communityName, property: this.assess.property } })
    },
    backIndex () {
      this.$router.push({ name: 'Index' })
    },
    onComplete () {
      console.log(this.moduleList)
      const list = []
      this.moduleList.forEach(item => {
        item.topic.forEach(v => {
          if (v.status === 1) {
            list.push({ id: v.id, serial: v.serial, status: v.status })
          }
        })
      })
      this.statusList = list
      console.log('list', list)
      this.popupShow = true
    },
    onUndone () {
      const list = []
      this.moduleList.forEach(item => {
        item.topic.forEach(v => {
          if (v.status === 0) {
            list.push({ id: v.id, serial: v.serial, status: v.status })
          }
        })
      })
      this.statusList = list
      console.log('list', list)
      this.popupShow = true
    }
  }
}
</script>

<style scoped>
/* .base {
  height: 400px;
  padding: 50px;
}
.module-wrap {
  padding: 0 50px;
}
.module {
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 0 0 10px 0;
} */
.container {
  height: 100vh;
  /* background: linear-gradient(122.66deg, rgba(108, 91, 222, 1) 0%, rgba(54, 39, 152, 1) 100%); */
  display:grid;
  grid-template-columns: 100%;
  grid-template-rows: 358px 1fr;
}
.assess {
   background: linear-gradient(122.66deg, rgba(108, 91, 222, 1) 0%, rgba(54, 39, 152, 1) 100%);
}
.nav {
  color: #fff;
  display: flex;
}
.nav > div {
  padding: 20px;
}
.head {
    /* height: 540px; */
    padding: 30px 50px 50px 50px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.assess-base > div {
  margin: 0 0 10px 0;
}
.community {
  font-size: 26px;
  font-weight: 600;
}
.property {
  font-size: 20px;
}
.assess-info {
  display:flex;
  justify-content: space-around;

}
.assess-info > div {
  width: 100px;
  text-align: center;
}
.total-num {
  font-size: 26px;
  font-weight: 600;
}

.module-wrap {
  background-color: #F5F7F9;
  /* border-radius: 20px 20px 0 0; */
  padding: 50px
}
.module {
  height: 100px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: 20px;
  margin: 0 0 20px 0;
}
.topic {
  padding: 20px;
  /* display: flex;
  justify-content: space-between; */
}
.topic-status {
  display: flex;
  justify-content: space-between;
}
.topic-content {
  background-color:#F5F7F9;
  font-size: 18px;
}
.status-main {
  padding: 12px;
}
.status-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 0 0 12px 0;
  background-color: rgb(235, 235, 235);
  border-radius: 20px;
}
</style>
